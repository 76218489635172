import React from 'react'
import { fontSizeMap } from '~/components/inputs/InputUtils'

const className = 'mb-1 block font-sansSerif text-p2 antialiased md:text-p2md'
export const LabelClassName = className

interface Props
  extends InputProps,
    React.LabelHTMLAttributes<HTMLLabelElement> {
  dangerouslySet?: string
}

const Label: React.FC<Props> = ({
  children,
  className,
  dangerouslySet,
  inputType = 'form',
  ...other
}) => {
  return (
    <label
      className={
        `${fontSizeMap[inputType]} block font-sansSerif antialiased` +
        (className ? ` ${className}` : '')
      }
      dangerouslySetInnerHTML={
        dangerouslySet
          ? {
              __html: `${dangerouslySet}`,
            }
          : undefined
      }
      {...other}
    >
      {children}
    </label>
  )
}

export default Label
